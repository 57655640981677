<template>
  <div class="home">
    <el-carousel :interval="8000" height="640px" class="home_card">
      <el-carousel-item v-for="item in imgList" :key="item + 'img'">
        <div
          :style="'background: url(' + item + ') no-repeat center center;'"
          class="home_card_item"
        ></div>
      </el-carousel-item>
    </el-carousel>
    <div class="home_intr">
      <div class="home_intr_left">
        <div class="home_intr_left_top">
          <div class="home_intr_left_top_content">
            紧跟国家战略导向 实现产业融合创新
            务实解决民之所需&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;金融孵化实体
            科技赋能生态 梦想支撑品牌 专业呈现未来
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            紧跟国家战略导向 实现产业融合创新
            务实解决民之所需&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;金融孵化实体
            科技赋能生态 梦想支撑品牌 专业呈现未来
          </div>
          <div class="home_intr_left_top_content">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            紧跟国家战略导向 实现产业融合创新
            务实解决民之所需&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;金融孵化实体
            科技赋能生态 梦想支撑品牌 专业呈现未来
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            紧跟国家战略导向 实现产业融合创新
            务实解决民之所需&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;金融孵化实体
            科技赋能生态 梦想支撑品牌 专业呈现未来
          </div>
        </div>
        <div class="home_intr_left_bottom">
          <div class="home_intr_left_bottom_content">
            <!-- 金融孵化实体 科技赋能生态 梦想支撑品牌 专业呈现未来 -->
          </div>
        </div>
      </div>
    </div>
    <div class="home_eventss">
      <div class="eventss_bg">
        <div class="eventss_title"></div>
        <div class="headTv">
          <div class="headBox">
            <div class="head_img">
              <!-- :interval="5000" -->
              <el-carousel
                arrow="never"
                indicator-position="none"
                :autoplay="false"
                ref="headCarousel"
                @change="carouselC"
              >
                <el-carousel-item
                  v-for="(item, index) in groupMemorabiliaList"
                  :key="index"
                >
                  <div
                    class="head_bg"
                    style="cursor: pointer"
                    @click="toDetails(item, index)"
                  >
                    <!-- <img
                      src="../../../static/newImg/img_loading.png"
                      class="img_loading"
                      v-show="imgSuffix"
                    /> -->
                    <div class="mask"></div>
                    <img
                      style="border-radius: 15px"
                      :src="item.newsCover"
                      width="100%"
                    />
                  </div>
                  <div class="head_item">
                    <div style="opadding-top: 20px">
                      <div
                        class="head_font1"
                        style="margin-right: 30px; width: 1100px"
                      >
                        {{
                          item.newsTitle && item.newsTitle.length > 86
                            ? item.newsIntroduction.slice(0, 86) + "..."
                            : item.newsTitle
                        }}
                      </div>
                      <div
                        class="head_font1"
                        style="opacity: 0.6; margin-top: 16px"
                      >
                        发布单位：{{ item.websiteNames }}
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="cornerMarkBox">
              <div
                :class="{ ckcss: cssCk == index }"
                class="cornerMark"
                v-for="(item, index) in 4"
                :key="index"
                @click="cornerCM(index)"
              >
                {{ index + 1 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home_img_bg">
      <div style="display: flex; justify-content: center" class="showMobile">
        <!-- :style="
          isMobile
            ? 'transform: translateY(-50px)'
            : 'transform: translateY(-100px)'
        " -->
        <div style="margin-top: 80px">
          <img
            src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-01-15/cab48165ddf3451b80ed5c0ce8978031.png"
            width="408px"
          />
        </div>
      </div>
      <div class="home_news" style="z-index: 99; position: relative">
        <div class="home_news_left">
          <!-- <div class="bgred"></div> -->
          <div class="home_news_left_title_dom">
            <div
              @click="thereJunp(newsList[0], 22)"
              class="home_news_left_title"
              style="
                font-weight: bold;
                font-size: 28px;
                color: #111218;
                letter-spacing: 1px;
                align-items: center;
                cursor: pointer;
              "
            >
              <div
                style="display: inline-block; margin-right: 10px"
                class="home_news_left_title_red"
              >
                新闻聚焦
              </div>
              |{{
                newsList[0].newsTitle && newsList[0].newsTitle.length > 23
                  ? newsList[0].newsTitle.substring(0, 23) + "..."
                  : newsList[0].newsTitle
              }}
            </div>
            <div class="wire"></div>
          </div>
          <div
            class="home_news_left_title_center_dom"
            style=""
            @click="thereJunp(newsList[0], 22)"
          >
            {{
              newsList[0].newsIntroduction &&
              newsList[0].newsIntroduction.length > 115
                ? newsList[0].newsIntroduction.substring(0, 115) + "..."
                : newsList[0].newsIntroduction
            }}
            <div style="position: absolute; right: 0; bottom: -5px">
              <img
                class="one"
                src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-01-15/f146e165ab0941979011d6798463056f.png"
                width="11px"
              />
              <img
                class="two"
                src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-01-15/f146e165ab0941979011d6798463056f.png"
                width="11px"
              />
              <img
                class="three"
                src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-01-15/f146e165ab0941979011d6798463056f.png"
                width="11px"
              />
            </div>
          </div>
          <div
            style="height: 0px; border: 1px dashed #e3e3e3; margin-bottom: 20px"
          ></div>
          <div
            class="home_news_left_list moveTop"
            @click="thereJunp(item, 22)"
            v-for="(item, index) in newsList1"
            :key="'newsList' + item.newsId"
            @mouseover="changeCarousel(index)"
          >
            <div class="circle"></div>
            <div class="home_news_left_list_title">{{ item.newsTitle }}</div>
            <div class="home_news_left_list_day">{{ item.publishTime }}</div>
          </div>
          <div
            style="height: 0px; border: 1px dashed #e3e3e3; margin-top: 20px"
          ></div>
        </div>
        <div class="home_news_bg">
          <el-carousel
            height="350px"
            indicator-position="none"
            ref="newsImgCarousel"
            arrow="never"
            @change="changeNewsImgCarousel"
            class="newsImgCarousel"
          >
            <el-carousel-item
              v-for="(item, index) in newsList1"
              :key="item.newsId + 'newsImg'"
            >
              <!-- <img
              src="../../static/newImage/img_loading.png"
              class="img_loading"
              v-show="imgSuffix"
            /> -->
              <img
                :src="item.newsCover + imgSuffix"
                v-show="index == 0"
                width="586px"
                @click="thereJunp(item, 22)"
              />
              <img
                :src="item.newsCover + imgSuffix"
                v-show="index == 1"
                width="586px"
                @click="thereJunp(item, 22)"
              />
              <img
                :src="item.newsCover + imgSuffix"
                v-show="index == 2"
                width="586px"
                @click="thereJunp(item, 22)"
              />
            </el-carousel-item>
          </el-carousel>
          <div class="home_news_bg_bottom">
            <div
              class="home_news_bg_bottom_title"
              v-if="newsList1.length"
              @click="thereJunp(newsList1[newsImageIndex], 22)"
            >
              {{ newsList1[newsImageIndex].newsTitle }}
            </div>
            <div>
              <div
                :class="
                  newsImageIndex == index
                    ? 'home_news_bg_bottom_Index active'
                    : 'home_news_bg_bottom_Index'
                "
                v-for="(i, index) in newsList1"
                @click="changeNewsImageIndex(index)"
                :key="index + 'newsImg1'"
              >
                {{ index + 1 }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 第一个 -->
      <div class="home_all">
        <div class="home_all_module">
          <div class="home_all_module_name">
            <div>集团要闻</div>
            <div
              class="home_all_module_name_more"
              @click="toPath('/press_center', 17)"
            >
              更多>>
            </div>
          </div>
          <div class="home_all_module_name">
            <div>产业动态</div>
            <div
              class="home_all_module_name_more"
              @click="toPath('/press_center', 16)"
            >
              更多>>
            </div>
          </div>
          <div class="home_all_module_name">
            <div>媒体报道</div>
            <div
              class="home_all_module_name_more"
              @click="toPath('/press_center', 15)"
            >
              更多>>
            </div>
          </div>
        </div>
        <div class="home_all_module">
          <div class="swiper-container swiper-container1">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="item in img_list1"
                :key="item.id"
                style="
                  width: 493px;
                  height: 235px;
                  overflow: hidden;
                  position: static;
                "
              >
                <!-- style="width: 400px; height: 163px; overflow: hidden" -->
                <!-- <img
                src="../../static/newImage/img_loading.png"
                class="img_loading"
                v-show="imgSuffix"
              /> -->
                <img
                  class="home_all_module_img"
                  :src="item.newsCover + imgSuffix"
                  style="width: 100%"
                  @click="thereJunp(item, 17)"
                />
                <div class="blackMack" @click="thereJunp(item, 17)"></div>
              </div>
            </div>
            <div class="swiper-pagination swiper-pagination1"></div>
          </div>
          <div class="swiper-container swiper-container2">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="item in img_list2"
                :key="item.id"
                style="width: 493px; height: 235px; overflow: hidden"
              >
                <!-- margin-top: 20px !important; -->
                <!-- <img
                src="../../static/newImage/img_loading.png"
                class="img_loading"
                v-show="imgSuffix"
              /> -->
                <img
                  class="home_all_module_img"
                  :src="item.newsCover + imgSuffix"
                  style="width: 100%"
                  @click="thereJunp(item, 16)"
                />
              </div>
            </div>
            <div class="swiper-pagination swiper-pagination2"></div>
          </div>
          <div class="swiper-container swiper-container3">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="item in img_list3"
                :key="item.id"
                style="width: 493px; height: 235px; overflow: hidden"
              >
                <!-- margin-top: 20px !important; -->
                <!-- <img
                src="../../static/newImage/img_loading.png"
                class="img_loading"
                v-show="imgSuffix"
              /> -->
                <img
                  class="home_all_module_img"
                  :src="item.newsCover + imgSuffix"
                  style="width: 100%"
                  @click="thereJunp(item, 15)"
                />
              </div>
            </div>
            <div class="swiper-pagination swiper-pagination3"></div>
          </div>
        </div>
        <div class="home_all_module">
          <div class="home_all_content" style="width: 32%; margin-right: 2%">
            <div
              class="home_all_content_item moveTop"
              @click="thereJunp(item, 17)"
              v-for="item in img_list1"
              :key="item.newsId"
            >
              <!-- {{ item.newsTitle }} -->
              {{
                item.newsTitle.length > 32
                  ? item.newsTitle.substring(0, 32) + "..."
                  : item.newsTitle
              }}
            </div>
          </div>
          <div class="home_all_content" style="width: 32%; margin-right: 2%">
            <div
              class="home_all_content_item moveTop"
              @click="thereJunp(item, 16)"
              v-for="item in img_list2"
              :key="item.newsId"
            >
              {{
                item.newsTitle.length > 32
                  ? item.newsTitle.substring(0, 32) + "..."
                  : item.newsTitle
              }}
            </div>
          </div>
          <div class="home_all_content" style="width: 32%">
            <div
              class="home_all_content_item moveTop"
              @click="thereJunp(item, 15)"
              v-for="item in img_list3"
              :key="item.newsId"
            >
              {{
                item.newsTitle.length > 32
                  ? item.newsTitle.substring(0, 32) + "..."
                  : item.newsTitle
              }}
            </div>
          </div>
        </div>
      </div>

      <!-- 第二个 -->
      <div class="home_all">
        <div class="home_all_module">
          <div class="home_all_module_name">
            <div>政府支持</div>
            <div
              class="home_all_module_name_more"
              @click="toPath('/press_center', 27)"
            >
              >>更多
            </div>
          </div>
          <div class="home_all_module_name">
            <div>社会贡献</div>
            <div
              class="home_all_module_name_more"
              @click="toPath('/press_center', 28)"
            >
              >>更多
            </div>
          </div>
          <div class="home_all_module_name">
            <div>热点专题</div>
            <div
              class="home_all_module_name_more"
              @click="toPath('/press_center', 24)"
            >
              >>更多
            </div>
          </div>
        </div>
        <!-- TODO -->
        <div class="home_all_module">
          <div class="swiper-container swiper-container4">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="item in img_list4"
                :key="item.id"
                style="width: 493px; height: 235px; overflow: hidden"
              >
                <!-- style="width: 384px; height: 150px; overflow: hidden" -->
                <!-- margin-top: 20px !important; -->
                <img
                  class="home_all_module_img redBase"
                  :src="item.newsCover"
                  style="width: 100%; transform: translate(0, -10%) scale(1.4)"
                  @click="thereJunp(item, 27)"
                />
              </div>
            </div>
            <div class="swiper-pagination swiper-pagination4"></div>
          </div>
          <div class="swiper-container swiper-container5">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="item in img_list5"
                :key="item.id"
                style="width: 493px; height: 235px; overflow: hidden"
              >
                <!-- margin-top: 20px !important; -->
                <img
                  class="home_all_module_img"
                  :src="item.newsCover"
                  style="width: 100%; transform: translate(0, -10%) scale(1.4)"
                  @click="thereJunp(item, 28)"
                />
              </div>
            </div>
            <div class="swiper-pagination swiper-pagination5"></div>
          </div>
          <div class="swiper-container swiper-container6">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="item in img_list6"
                :key="item.id"
                style="height: 235px; overflow: hidden"
              >
                <!-- margin-top: 20px !important; -->
                <img
                  class="home_all_module_img"
                  :src="item.newsCover"
                  style="width: 100%; transform: translate(0, -10%) scale(1.4)"
                  @click="thereJunp(item, 24)"
                />
              </div>
            </div>
            <div class="swiper-pagination swiper-pagination6"></div>
          </div>
        </div>
        <div class="home_all_module">
          <div class="home_all_content" style="width: 32%; margin-right: 2%">
            <div
              class="home_all_content_item moveTop"
              @click="thereJunp(item, 27)"
              v-for="item in img_list4"
              :key="item.newsId"
            >
              {{
                item.newsTitle.length > 32
                  ? item.newsTitle.substring(0, 32) + "..."
                  : item.newsTitle
              }}
            </div>
          </div>
          <div class="home_all_content" style="width: 32%; margin-right: 2%">
            <div
              class="home_all_content_item moveTop"
              @click="thereJunp(item, 28)"
              v-for="item in img_list5"
              :key="item.newsId"
            >
              {{
                item.newsTitle.length > 32
                  ? item.newsTitle.substring(0, 32) + "..."
                  : item.newsTitle
              }}
            </div>
          </div>
          <div class="home_all_content" style="width: 32%">
            <div
              class="home_all_content_item moveTop"
              @click="thereJunp(item, 24)"
              v-for="item in img_list6"
              :key="item.newsId"
            >
              {{
                item.newsTitle.length > 32
                  ? item.newsTitle.substring(0, 32) + "..."
                  : item.newsTitle
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home_img">
      <div class="home_img_box">
        <div class="home_img_title"></div>
        <div class="home_img_center">
          浙江中峪控股集团有限公司
          是一家涉足商贸、拍卖交易所、职业培训学校、中医大健康、医疗、生物制药、网络科技、养老产业、金融等十大闭环产业的集团化公司，集团以国家政策为导向，以大众创业，万众创新为初心，跨界融合、科技创新、数字经济、跨产业赋能、多元化经营、服务于各生产企业的同时，为市场培育孵化更多优质的人才。同时紧跟国家战略部署，以乡村振兴为战略目标…
        </div>
        <div class="home_img_details">
          <div v-for="(item, index) in onlist" :key="index" class="box">
            <div class="box_font" v-show="index == 0">
              {{ item.number }}
              <span class="span_font">大</span>
              <div class="title_font">{{ item.title }}</div>
            </div>
            <div class="box_font" v-show="index == 1">
              {{ item.number }} <span class="span_font">+</span>
              <div class="title_font" style="left: 10%">{{ item.title }}</div>
            </div>
            <div class="box_font" v-show="index == 2">
              {{ item.number }} <span class="span_font">余家</span>
              <div class="title_font" style="left: 10%">{{ item.title }}</div>
            </div>
            <div class="box_font" v-show="index == 3">
              {{ item.number }} <span class="span_font">年</span>
              <div class="title_font" style="left: 0">{{ item.title }}</div>
            </div>
            <div v-show="index < 3" class="home_img_single"></div>
          </div>
        </div>
        <div class="see_all">
          <div
            @click.stop="all_group_company"
            style="
              cursor: pointer;
              position: absolute;
              z-index: 29;
              left: 50%;
              transform: translate(-50%);
            "
          >
            查看更多
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div style="min-width: 1600px">
        <div class="homt_line"></div>
      </div>
    </div>
    <div style="margin-top: 25px">
      <demo></demo>
    </div>
    <!-- <div class="home_ten">
      <div class="home_box home_all">
        <div class="box">
          <div class="boxImg">
            第一版
            <div class="centre" v-if="container7">
              <img
                style="
                  width: 661px;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                "
                src="../../static/newImage/chanye1.png"
                alt=""
              />
              <div
                class="all-btn"
                style="margin-top: 283px; margin-bottom: 25px"
              >
                <div
                  class="l-btn"
                  style="margin-left: 90px"
                  @click="toHomePath(1, { id: 1 })"
                  :class="{ centreSty: container7.activeIndex == 12 }"
                >
                  养老板块
                </div>
                <div
                  class="l-btn"
                  style="margin-right: 90px"
                  @click="toHomePath(10, { id: 9 })"
                  :class="{
                    centreSty:
                      container7.activeIndex == 11 ||
                      container7.activeIndex == 1,
                  }"
                >
                  制药板块
                </div>
              </div>
              <div class="all-btn" style="margin-bottom: 25px">
                <div
                  class="l-btn"
                  @click="toHomePath(2, { id: 5 })"
                  :class="{
                    centreSty:
                      container7.activeIndex == 13 ||
                      container7.activeIndex == 3,
                  }"
                >
                  中峪商贸
                </div>
                <div
                  class="l-btn"
                  @click="toHomePath(9, { id: 8 })"
                  :class="{
                    centreSty:
                      container7.activeIndex == 10 ||
                      container7.activeIndex == 0,
                  }"
                >
                  医院板块
                </div>
              </div>
              <div class="all-btn" style="margin-bottom: 25px">
                <div
                  class="l-btn"
                  style="margin-left: 60px"
                  @click="toHomePath(2, { id: 3 })"
                  :class="{ centreSty: container7.activeIndex == 4 }"
                >
                  中峪学校
                </div>
                <div
                  class="l-btn"
                  style="margin-right: 60px"
                  @click="toHomePath(8, { id: 4 })"
                  :class="{ centreSty: container7.activeIndex == 9 }"
                >
                  金生投资
                </div>
              </div>
              <div class="all-btn" style="margin-bottom: 25px">
                <div
                  class="l-btn"
                  style="margin-left: 150px"
                  @click="toHomePath(3, { id: 7 })"
                  :class="{ centreSty: container7.activeIndex == 5 }"
                >
                  麒典信息科技
                </div>
                <div
                  class="l-btn"
                  style="margin-right: 150px"
                  @click="toHomePath(7, { id: 1 })"
                  :class="{ centreSty: container7.activeIndex == 8 }"
                >
                  中峪交易市场
                </div>
              </div>
              <div class="all-btn">
                <div
                  class="l-btn"
                  style="margin-left: 350px"
                  @click="toHomePath(4, { id: 6 })"
                  :class="{ centreSty: container7.activeIndex == 6 }"
                >
                  道地药材
                </div>
                <div
                  class="l-btn"
                  style="margin-right: 350px"
                  @click="toHomePath(5, { id: 2 })"
                  :class="{ centreSty: container7.activeIndex == 7 }"
                >
                  中峪数交
                </div>
              </div>
            </div>
            <div class="home_all_item" id="scrollMedicine">
              <div class="swiper-container swiper-container7">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide home_all_item_index"
                    v-for="(item, index) in Industry_category"
                    :key="item.id + 'img222'"
                    @click="toHomePath(index, item)"
                    :data-index1="index"
                  >
                    <div class="home_all_module">
                      <img
                        class="home_all_module_img"
                        :src="item.img"
                        style="height: 400px"
                      />
                    </div>
                    <div class="home_all_module home_all_bg">
                      <div class="home_all_module_black">
                        <div class="home_all_module_black_intro active">
                          <div class="home_all_module_black_intro_title">
                            {{ item.text }}
                            <div
                              class="home_all_module_black_intro_title_red"
                            ></div>
                          </div>
                          <div class="home_all_module_black_intro_content">
                            {{ item.content }}
                          </div>
                          <div class="home_all_module_black_intro_more">
                            查看更多 >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="home_all_module" style="width: 400px">
                      <div class="home_all_content">
                        <div
                          class="home_all_content_item moveTop"
                          v-for="item1 in img_list1"
                          @click="thereJunp(item1, 17)"
                          :key="item1.id"
                        >
                          {{ item1.newsTitle }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            第一版
          </div>
        </div>
      </div>
    </div> -->
    <div style="position: relative">
      <div class="culture_top_img"></div>
      <div class="culture_img_bg">
        <div class="culture_propaganda"></div>
        <div
          style="
            font-weight: 500;
            font-size: 24px;
            color: #ffe7c5;
            text-align: center;
            transform: translateY(300px);
          "
        >
          <div>
            紧跟国家战略导向，实现产业融合创新务实解决民之所需，金融孵化实体科技赋能生态，梦想支撑品牌，专业呈现未来
          </div>
          <div style="margin-top: 15px">
            立民族志气，创世界品牌，企业的发展，一切以国家政策为导向，不忘初心，牢记使命，为社会创造价值
          </div>
        </div>
        <el-dialog
          class="showtvDialog"
          title=""
          :close-on-click-modal="false"
          :visible.sync="centerDialogVisible"
          :width="isMobile ? '100%' : '50%'"
          :top="isMobile ? '20vh' : '15vh'"
          append-to-body
          :center="true"
          :show-close="false"
          :before-close="handleClose"
        >
          <div style="text-align: center">
            <img
              style="
                position: absolute;
                right: -10px;
                top: 41%;
                cursor: pointer;
                z-index: 9999;
              "
              @click="handleClose"
              :style="isMobile ? 'right: 10px;' : ''"
              src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-01-16/4f0be8572a6443febf0102084d02dd47.png"
              width="60px"
            />
            <video
              ref="bainianvideo"
              loop="true"
              controls
              style="border-radius: 12px"
              src=""
              width="100%"
              type="video/mp4"
            ></video>
          </div>
        </el-dialog>

        <div
          style="
            height: 760px;
            z-index: 5;
            transform: translateY(35%);
            position: relative;
          "
        >
          <div class="examples">
            <div class="bannerBox">
              <div class="swiper-container99">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    :style="`background: url(${item.bannerVideoCover})`"
                    :data-item="index"
                    id="swiperSlide1"
                    v-for="(item, index) in newsList2"
                    :key="index"
                  >
                    <div
                      style="
                        width: 1020px;
                        display: flex;
                        justify-content: center;
                      "
                    >
                      <div class="mask"></div>
                      <div class="frame"></div>
                      <div class="open1"></div>
                      <!-- <div
                        style="background: none; z-index: 2"
                        class="moveTop2"
                      >
                        <div class="swiper_title" style="background: none">
                          {{ item.title }}
                        </div>
                        <div class="swiper_font" style="background: none">
                          {{ item.title }}
                        </div>
                        <div class="home_module_8_more"></div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="society_contribution">
        <div class="social_contribution_title">
          <div class="social_contribution_title_img"></div>
        </div>
        <div style="height: 450px; position: relative">
          <div class="examples">
            <div style="heigth: 450px; overflow: hidden">
              <div class="bannerBox">
                <div class="swiper-container92">
                  <div class="swiper-wrapper">
                    <div
                      class="swiper-slide"
                      id="swiperSlide1"
                      :style="`background: url(${item.img})`"
                      v-for="(item, index) in banner"
                      :key="index"
                      :virtualIndex="indexC2"
                      @click="swiper_C2(index)"
                    ></div>
                  </div>
                </div>
                <div
                  ref="paginationBullets"
                  class="swiper-pagination92"
                  slot="pagination"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="enterprise_history">
      <div class="enterprise_history_title">
        <div class="enterprise_history_title_img"></div>
        <div class="enterprise_history_blank"></div>
        <div class="enterprise_history_wave_fa_momentarily">
          <div class="c_dom">
            <div class="enterprise_history_wave_dom_one" @click="one"></div>
            <div class="enterprise_history_wave_dom_two" @click="two"></div>
            <div class="enterprise_history_wave_dom_three" @click="three"></div>
            <div class="enterprise_history_wave_dom_four" @click="four"></div>
          </div>
        </div>
        <!-- <div class="enterprise_history_wave_fa">
          <div class="enterprise_history_wave">
            <div
              v-for="(item, index) in courseList"
              :key="index"
              :class="'enterprise_history_box' + index"
            >
              <div class="enterprise_history_alone">
                <i class="enterprise_history_year">{{ item.courseYear }}</i>
                <div
                  style="
                    height: 38px;
                    text-align: center;
                    position: relative;
                    margin-top: 20px;
                    margin-bottom: 45px;
                  "
                >
                  <div class="enterprise_history_circle"></div>
                </div>
                <div style="cursor: pointer">
                  <div style="position: relative; width: 360px; height: 168px">
                    <div class="enterprise_history_img"></div>
                  </div>
                  <div class="enterprise_history_alone_title">
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="enterprise_history_all">
          <div class="enterprise_history_all_see" @click="jumpAll"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Pagination, Autoplay } from "swiper";
import axios from "axios";
import { getGroupMemorabilia, getBannerList } from "@/api/login";
import demo from "../demo 1.0/demo1.0.vue";
export default {
  name: "Home",
  components: {
    demo,
  },
  data() {
    return {
      groupMemorabiliaList: [],
      imgList: [
        require("../../static/newImage/怀.jpg"),
        require("../../static/newImage/banner3.gif"),
        require("../../static/newImage/banner3_m.gif"),
        require("../../static/newImage/shenghuo.jpg"),
        // require("../../static/newImage/banner4.jpg"),
      ],
      newsList: [],
      newsList1: [],
      newsList2: [],
      newsImgList: [
        require("../../static/newImage/home1.jpg"),
        require("../../static/newImage/home2.jpg"),
        require("../../static/newImage/home3.jpg"),
      ],
      newsIndex: 22,
      newsImageIndex: 0,
      animationFont:
        "立民族志气，创世界品牌|企业的发展，一切以国家政策为导向|不忘初心，牢记使命，为社会创造价值|继承发扬中医文化，为祖国中医药事业发展做贡献|守护道地药材，发掘祖传秘方，传承中医国粹，提升国民生活品质|振兴乡村经济，促进市场经济循环，提供创造就业岗位，助力地方产业融合发展科技融入生命，专业服务生活，让养老从心定义！|助力国家就业体系，造就社会专业人才",
      fontInterval: null,
      fontTimeout: null,
      Industry_category: [
        {
          id: "10",
          text: "养老板块",
          content: "板块正在建设中……",
          img: "http://bizpub.oss-cn-hangzhou.aliyuncs.com/30c0338582b04652be40f3cad042c7df养老w.jpg",
        },
        {
          id: "5",
          text: "中峪商贸",
          content:
            "中峪商贸为四川省泸州市地方政府重点招商引资项目，是一家承载孵化落地集团全产业链使命的运营公司，以其独特商业运营架构和科学创新的市场培育体系，助推地方中小企业产品流通，促进地方区域经济发展，优化商品流通渠道。协同中峪拍交所为集团产业导入高粘度市场流量，协同中峪职业培训学校共同孵化输送业务体系人才，为地方解决就业、创业而努力。",
          img: "http://bizpub.oss-cn-hangzhou.aliyuncs.com/f3ca51e6682a40b284ae67b1cf0f9c6a贸易.jpg",
        },
        {
          id: "3",
          text: "中峪学校",
          content:
            "中峪职业培训学校是一家结合国家职业教育体系改革指导政策，肩负起特色职业技能培育，为中峪产业培育人才，给社会输送专业人才，同时解决就业再就业问题，为地方职业教育发展提供助力，为养老和文化产业发展提供助力。",
          img: "http://bizpub.oss-cn-hangzhou.aliyuncs.com/d06c185094f14e479e4731fc7280ff4c学校.jpg",
        },
        {
          id: "7",
          text: "麒典信息科技",
          content:
            "麒典信息科技是一家集大数据服务、核心技术研发、技术咨询服务、区块链技术应用、软件开发等科技研发公司，为集团产业链数字化运作提供强大技术输出，肩负集团产业链大数据信息储存输送和科技数字化运作重任，同时为助力地方产业数字化发展提供强大技术支撑，承载银发经济链产品研发和制造。",
          img: "http://bizpub.oss-cn-hangzhou.aliyuncs.com/6680f9a48b704f02a86e7a071a94ccd0信息科技.jpg",
        },
        {
          id: "6",
          text: "道地药材",
          content:
            "浙江中峪农业发展有限责任公司（简称“中峪农业”），秉持以绿水青山就是金山银山的发展理念，传承发展中医药产业为己任的宗旨，以数字化改革和乡村振兴为初心，致力于在农业领域推动创新数字化发展，专注于道地药材从源头孵化与保护产业生态，带动地方就业、加工、仓储物流等，推动地方文旅、科研、产教研学、人才培养等多元化产业发展；同时积极探索药、风、光、零碳、能源产业带的同步推进。在集团内循环产业链赋能下，跨界融合部署，遵循国家政策，紧跟国家战略，以“数字经济新农业 乡村振兴新载体”的创新产业布局，致力于实现乡村产业振兴和多维推动共同富裕，打造数字化新农业的全新产业标杆。",
          img: require("../../static/newImage/home10.jpg"),
        },
        {
          id: "2",
          text: "中峪数交",
          content:
            "四川中峪拍卖交易服务有限责任公司简称“中峪数交”，为中峪集团生态内循环闭环产业链之一，具有多行业背景和资源优势，整合融通了多品种商品资源、数字化拍卖服务、仓储物流资源和资讯服务平台，充分结合集团闭环产业优势，为大众创业、万众创新服务，实现跨界融合，优化产能拉动内需，以“发展产业电商、服务实体经济”为使命，积极响应国家乡村振兴、数字中国建设、数商兴农号召，以“产业基地+在线贸易+科技应用+数字拍卖服务”为功能定位，构建产业链生态闭环，致力于产业创新服务综合体建设，为实现数字经济新农业，乡村振兴新载体发展起到强大支撑和赋能，同时发挥资产数字化拍卖平台的优势，为实体产业赋能创收和大数据客户引流扩容，为加快推进数字时代促进数字经济和实体经济深度融合尽一份责任。",
          img: "http://bizpub.oss-cn-hangzhou.aliyuncs.com/c1ccf2fa3f9e4fa68737ff319e89020d拍卖.jpg",
        },
        {
          id: "1",
          text: "中峪交易市场",
          content:
            "中峪日用品交易市场均为中峪集团十大闭环产业之一，具有多行业背景和资源优势，整合融通了多品种商品资源、金融服务、仓储物流资源和资讯服务平台，充分结合集团闭环产业优势，为大众创业、万众创新服务，实现跨界融合，帮助中小企业实现去库存、优化产能拉动内需，以“发展产业电商、服务实体经济”为使命，积极响应国家乡村振兴、数字中国建设、数商兴农号召，以“产业基地+在线贸易+科技应用+金融服务”为功能定位，构建产业链生态闭环，致力于产业创新服务综合体建设，为中峪道地药材产业链发展建立资产数字化交易平台，为实体产业赋能创收和大数据客户引流扩容，为加快推进数字时代促进数字经济和实体经济深度融合尽一份责任。",
          img: "http://bizpub.oss-cn-hangzhou.aliyuncs.com/a9e5bb46974d4d34916934526c623ed3商务配图.jpg",
        },
        {
          id: "4",
          text: "金生投资",
          content:
            "投资管理公司不仅仅为各产业提供金融助力，更是为了集团进一步发展服务，在集团稳健发展的基础上，进行分层级的资本良性运作，为社会创造更多的财富，为共同富裕助力。",
          img: "http://bizpub.oss-cn-hangzhou.aliyuncs.com/f946dd953423416fa472ff1ede2de034投2资.jpg",
        },
        {
          id: "8",
          text: "医院板块",
          content: "板块正在建设中……",
          img: "http://bizpub.oss-cn-hangzhou.aliyuncs.com/ca8e0b6b57b342e7a253c538e45f929d医院.jpg",
        },
        {
          id: "9",
          text: "制药板块",
          content:
            "生物制药坚持中医药原创思维，深入发掘中医药精华，是一家传承古代经典名方、名老中医经验方、传统民族药业、新药研发为一体的高新技术制药研发公司。为传承中医药文化，促进中医药特色发展，提升中医药产业水平做出积极贡献，是中峪道地药材的制药基地，是中峪医院的药材基地，为中峪康养产业提供优质药食同源。",
          img: "http://bizpub.oss-cn-hangzhou.aliyuncs.com/d73dee92df23467c94003b5604c98947制药.jpg",
        },
      ],
      img_list1: [],
      img_list2: [],
      img_list3: [],
      img_list4: [],
      img_list5: [],
      img_list6: [],
      device: false,
      changecyIndex: 0,
      pxValue: 0,
      swiperImg1: null,
      swiperImg2: null,
      swiperImg3: null,
      swiperImg4: null,
      swiperImg5: null,
      swiperImg6: null,
      container7: null,
      liudongInterval: null,
      recommendList: [],
      headIndex: 0,
      cssCk: 0,
      isMobile: false,
      imgSuffix: "?x-oss-process=image/resize,m_lfit,w_300",
      onlist: [
        {
          number: 10,
          title: "产业版块",
        },
        {
          number: 1000,
          title: "客户及工作人员",
        },
        {
          number: 10,
          title: "合作伙伴及子公司",
        },
        {
          number: 2019,
          title: "中峪控股集团成立",
        },
      ],
      indexC: 0,
      banner: [
        {
          id: 1,
          img: require("../../static/newImage/test/1.jpg"),
        },
        {
          id: 2,
          img: require("../../static/newImage/test/2.jpg"),
        },
        {
          id: 3,
          img: require("../../static/newImage/test/7.jpg"),
        },
        {
          id: 4,
          img: require("../../static/newImage/test/6.jpg"),
        },
        {
          id: 5,
          img: require("../../static/newImage/test/5.jpg"),
        },
      ],
      indexC2: null,
      video: null,
      centerDialogVisible: false,
      courseList: [
        { courseYear: "2019年5月", img: "", title: "中峪控股集团成立" },
        { courseYear: "2022年1月", img: "", title: "中峪集团学校版块落地柯桥" },
        {
          courseYear: "2022年10月",
          img: "",
          title: "中峪集团视拓版块郑重按下开启键",
        },
        { courseYear: "2023年3月", img: "", title: "中峪集团农业板块正式启动" },
      ],
    };
  },
  created() {
    // 判断是否是手机设备
    const reg =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    this.isMobile = reg.test(navigator.userAgent);
    if (this.isMobile) {
      localStorage.setItem("device", this.isMobile);
    } else {
      localStorage.setItem("device", this.isMobile);
    }
  },

  mounted() {
    setTimeout(() => {
      console.log(window.innerWidth);
      if (window.innerWidth > 1600) {
        // document.querySelector(".culture_img_bg").style.backgroundSize =
        //   "contain";
        // document.querySelector(".culture_top_img").style.height = "198px";
        // document.querySelector(
        //   ".enterprise_history_wave_fa"
        // ).style.backgroundPositionX = "150px";
      }
    }, 200);
    this.imgSuffixTime = setTimeout(() => {
      this.imgSuffix = "";
    }, 4000);
    // imgList
    const reg =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    if (reg.test(navigator.userAgent)) {
      this.imgList.splice(0, 1);
    } else {
      this.imgList.splice(1, 1);
    }
    if (this.$refs.newsImgCarousel) {
      this.slideBanner();
    }
    Swiper.use([Pagination, Autoplay]);
    this.getNewsList();

    this.changeNewsIndex(22);
    let that = this;
    setTimeout(() => {
      let mySwiper = new Swiper(".swiper-container99", {
        direction: "horizontal", //滑动方向，可设置水平(horizontal)或垂直(vertical)。
        loop: true, // 设置为true 则开启loop模式
        touchRatio: 0.5, //触摸变慢
        slidesPerView: "auto", // 设置slider容器能够同时显示的slides数量(carousel模式)。类型：number or auto
        centeredSlides: true, // 设定为true时，active slide会居中，而不是默认状态下的居左。
        spaceBetween: 110, // 在slide之间设置距离（单位px）。
        // spaceBetween: 220, // 在slide之间设置距离（单位px）。
        on: {
          slideChangeTransitionEnd: function (e) {
            that.indexC = e.realIndex;
            console.log(that.newsList2);
            that.PcompanyId = that.newsList2[that.indexC].companyId;
            // that.getChildProjectList();
          },
          click(e) {
            if (
              e.realIndex == that.indexC &&
              !e.clickedSlide.className.includes("swiper-slide-next") &&
              !e.clickedSlide.className.includes("swiper-slide-prev")
            ) {
              that.swiper_C(that.newsList2[e.realIndex]);
              return;
            } else if (
              e.realIndex == that.indexC &&
              !e.clickedSlide.className.includes("swiper-slide-next")
            ) {
              e.slidePrev();

              // that.PcompanyId =
              return;
            } else if (
              e.realIndex == that.indexC &&
              !e.clickedSlide.className.includes("swiper-slide-prev")
            ) {
              e.slideNext();
              return;
            }
          },
        },
      });
      let mySwiper2 = new Swiper(".swiper-container92", {
        direction: "horizontal", //滑动方向，可设置水平(horizontal)或垂直(vertical)。
        loop: true, // 设置为true 则开启loop模式
        autoplay: {
          delay: 1500, // ms
          disableOnInteraction: false,
        },
        slidesPerView: 3, // 设置slider容器能够同时显示的slides数量(carousel模式)。类型：number or auto
        centeredSlides: true, // 设定为true时，active slide会居中，而不是默认状态下的居左。
        spaceBetween: 40, // 在slide之间设置距离（单位px）。
        //   loopAdditionaSlider: 0, // loop模式下会在slides前后复制若干个slide,，前后复制的个数不会大于原总个数。
        slideToClickedSlide: true,
        pagination: {
          el: ".swiper-pagination92",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    }, 300);
    this.groupMemorabilia();
    this.getBannerListF();
  },
  methods: {
    getBannerListF() {
      const data = {
        bannerType: 2,
      };
      axios
        .get(`https://t2.zyjkjt.net/cms/api/app/banner/list?bannerType=2`)
        .then((res) => {
          console.log(res.data.data.list, "123");
          this.newsList2 = res.data.data.list;
        });
      // getBannerList(data).then
    },
    handleClose() {
      this.$refs["bainianvideo"].pause();
      this.$refs["bainianvideo"].src = "";
      this.centerDialogVisible = false;
    },

    close() {
      this.centerDialogVisible = false;
      // let video = this.$refs[bainianvideo].pause;
      // video.pause();
      // video.src = "";
      this.video = null;
    },
    swiper_C(item) {
      console.log(item, document.createElement("video"));
      // let video = document.createElement("video");
      // video.src = item.bannerVideo;
      // video.autoplay = true;
      // video.muted = false;

      // this.centerDialogVisible = true;
      // this.video = item.bannerVideo;

      const reg = /iPhone|iPad|iPod/i;
      const ios = reg.test(navigator.userAgent);
      console.log(ios);
      if (!ios) {
        this.centerDialogVisible = true;
        console.log(this.$refs["bainianvideo"]);
        this.$nextTick(() => {
          if (
            navigator.userAgent.includes("Safari") &&
            !navigator.userAgent.includes("Chrome")
          ) {
            this.$refs["bainianvideo"].src = item.bannerVideo;
          } else {
            this.$refs["bainianvideo"].src = item.bannerVideo;
          }
          this.$refs["bainianvideo"].play();
        });
      } else {
        var video = document.createElement("video");
        video.src = item.bannerVideo;
        video.autoplay = true; // 自动播放
        video.loop = true; // 循环播放
        video.play(); // 如果想要重新播放
      }
    },
    groupMemorabilia() {
      const data = {
        recommend: 1,
        websiteId: 2,
      };
      getGroupMemorabilia(data).then((res) => {
        let arr = res.data.data.list.slice(0, 4);
        this.groupMemorabiliaList = arr;
      });
    },
    four() {
      this.$router.push({
        path: "/grouph_istory",
      });
      window.scrollTo(0, 2267);
    },
    three() {
      this.$router.push({
        path: "/grouph_istory",
      });
      window.scrollTo(0, 1840);
    },
    two() {
      this.$router.push({
        path: "/grouph_istory",
      });
      window.scrollTo(0, 1260);
    },
    one() {
      this.$router.push({
        path: "/grouph_istory",
      });
      window.scrollTo(0, 600);
    },
    jumpAll() {
      this.$router.push({
        path: "/grouph_istory",
      });
      window.scrollTo(0, 0);
    },
    swiper_C2(index) {
      this.indexC2 = index;
    },
    slideBanner() {
      //选中的轮播图
      var box = document.querySelector(
        ".newsImgCarousel .el-carousel__container"
      );
      var startPoint = 0;
      var stopPoint = 0;
      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      };
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this;
      box.addEventListener("touchend", function () {
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.newsImgCarousel.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.newsImgCarousel.prev();
          return;
        }
      });
    },
    changeNewsIndex(index) {
      this.newsIndex = index;
      axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?current=1&size=3&websiteCategoryId=${index}`
        )
        .then(async (res) => {
          this.newsList = res.data.data.list;
          console.log(res);
          this.newsList1 = [
            res.data.data.list[0],
            res.data.data.list[1],
            res.data.data.list[2],
          ];
          console.log(this.newsList1);
        });
    },
    changeNewsImageIndex(index) {
      this.newsImageIndex = index;
      this.$refs.newsImgCarousel.setActiveItem(index);
    },
    changeNewsImgCarousel(index) {
      this.newsImageIndex = index;
    },
    toPath(path, id) {
      this.$router.push({ path, query: { websiteCategoryId: id } });
      window.scrollTo(0, 0);
    },
    all_group_company() {
      this.$router.push({
        path: "/contact_us",
      });
      window.scrollTo(0, 0);
    },
    async getNewsList() {
      //集团要闻
      await axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?current=1&size=10&websiteCategoryId=17`
        )
        .then(async (res) => {
          this.img_list1 = res.data.data.list.splice(0, 4);
          this.$nextTick(() => {
            this.swiperImg1 = new Swiper(".swiper-container1", {
              loop: true,
              observer: true, //开启动态检查器，监测swiper和slide
              observeParents: true, //监测Swiper 的祖/父元素
              pagination: {
                el: ".swiper-pagination1",
              },
            });
            // 产业发展初始化
            const that = this;
            this.container7 = new Swiper(".swiper-container7", {
              initialSlide: 2,
              loop: true,
              slidesPerView: 3,
              spaceBetween: 30,
              freeMode: true,
              observer: true, //开启动态检查器，监测swiper和slide
              observeParents: true, //监测Swiper 的祖/父元素
              on: {
                transitionEnd: function (swiper) {
                  swiper.slideTo(swiper.activeIndex, 1000, false);
                },
                click: function (swiper) {
                  if (swiper.activeIndex == 11) {
                    that.toHomePath(10, { id: 9 });
                  }
                  if (swiper.activeIndex == 13) {
                    that.toHomePath(2, { id: 5 });
                  }
                  if (swiper.activeIndex == 11) {
                    that.toHomePath(9, { id: 8 });
                  }
                },
              },
            });
            this.liudong(); //产业发展按钮自动流动
          });
        });
      await axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?current=1&size=10&websiteCategoryId=16`
        )
        .then(async (res) => {
          this.img_list2 = res.data.data.list.splice(0, 4);
          this.$nextTick(() => {
            this.swiperImg2 = new Swiper(".swiper-container2", {
              loop: true,
              observer: true, //开启动态检查器，监测swiper和slide
              observeParents: true, //监测Swiper 的祖/父元素
              pagination: {
                el: ".swiper-pagination2",
              },
            });
          });
        });
      await axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?current=1&size=10&websiteCategoryId=15`
        )
        .then(async (res) => {
          this.img_list3 = res.data.data.list.splice(0, 4);
          this.$nextTick(() => {
            this.swiperImg3 = new Swiper(".swiper-container3", {
              loop: true,
              observer: true, //开启动态检查器，监测swiper和slide
              observeParents: true, //监测Swiper 的祖/父元素
              pagination: {
                el: ".swiper-pagination3",
              },
            });
          });
        });

      await axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?current=1&size=10&websiteCategoryId=27`
        )
        .then(async (res) => {
          this.img_list4 = res.data.data.list.splice(0, 4);
          this.$nextTick(() => {
            this.swiperImg4 = new Swiper(".swiper-container4", {
              loop: true,
              observer: true, //开启动态检查器，监测swiper和slide
              observeParents: true, //监测Swiper 的祖/父元素
              pagination: {
                el: ".swiper-pagination4",
              },
            });
          });
        });
      await axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?current=1&size=10&websiteCategoryId=28`
        )
        .then(async (res) => {
          this.img_list5 = res.data.data.list.splice(0, 4);
          this.$nextTick(() => {
            this.swiperImg5 = new Swiper(".swiper-container5", {
              loop: true,
              observer: true, //开启动态检查器，监测swiper和slide
              observeParents: true, //监测Swiper 的祖/父元素
              pagination: {
                el: ".swiper-pagination5",
              },
            });
          });
        });
      await axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?current=1&size=10&websiteCategoryId=24`
        )
        .then(async (res) => {
          this.img_list6 = res.data.data.list.splice(0, 4);
          this.$nextTick(() => {
            this.swiperImg6 = new Swiper(".swiper-container6", {
              loop: true,
              observer: true, //开启动态检查器，监测swiper和slide
              observeParents: true, //监测Swiper 的祖/父元素
              pagination: {
                el: ".swiper-pagination6",
              },
            });
          });
        });
    },
    toHomePath(index, item) {
      if (index == 5) {
        window.open("https://sj.zoneyu.net/");
      } else if (index == 4) {
        window.open("https://www.zoneyu.cc/");
      } else if (index == 3) {
        window.open("https://www.zoneyu.com.cn/");
      } else {
        this.$router.push({
          path: "/industrial_development",
          query: { id: item.id },
        });
      }
    },
    changeCarousel(index) {
      this.$refs.newsImgCarousel.setActiveItem(index);
    },
    thereJunp(item, x) {
      console.log(item.x);
      this.$router.push({
        path: "/press_center_DetailPage",
        query: { v: item.newsId, websiteCategoryId: x },
      });
    },
    liudong() {
      this.liudongInterval = setInterval(() => {
        this.container7.slideNext(1000, false);
      }, 8000);
    },
    carouselC(e) {
      this.headIndex = e;
      this.cssCk = e;
    },
    toDetails(item, index) {
      console.log(item, index);
      console.log(item.categoryIds.split(","));
      let array1 = item.categoryIds.split(",");
      let array2 = ["17", "16", "15", "27", "28", "24", "22"];
      const intersection = array1.filter((item) => array2.includes(item));

      // 获取交集的第一项
      const firstItem = intersection.length > 0 ? intersection[0] : null;

      console.log(firstItem);
      // return;
      this.$router.push({
        path: "/press_center_DetailPage",
        query: { v: item.newsId, websiteCategoryId: firstItem },
      });
      // this.$router.push({
      //   path: `/newsListDetail?id=${item.newsId}&type=${
      //     item.websiteNames
      //   }&index=0`,
      // });
    },
    cornerCM(index) {
      this.cssCk = index;
      this.headIndex = index;
      this.$refs.headCarousel.setActiveItem(index);
    },
  },
  beforeDestroy() {
    window.stop ? window.stop() : document.execCommand("Stop");
    clearInterval(this.liudongInterval);
  },
};
</script>

<style scoped lang="scss">
.home {
  min-width: 1600px;
  background: #edeae6;
  // width: 100%;
  .home_title {
    width: 1600px;
    background: #e11515;
    color: #fff;
    padding: 0;
    height: 41px;
    line-height: 41px;

    .home_title_list {
      display: flex;
      width: 1600px;
      margin: 0 auto;

      .home_title_list_item {
        display: block;
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 800;
        padding: 0;
        cursor: pointer;

        span {
          margin-left: 35px;
        }
      }
    }
  }

  .home_card {
    .home_card_item {
      width: 100%;
      height: 100%;
      background-size: cover !important;
    }
  }

  .home_intr {
    display: flex;

    .home_intr_bg {
      flex: 1;
      height: 65px;
      background: #34476e;
      text-align: end;

      img {
        margin: 25px 20px 0 0;
      }
    }

    .home_intr_left {
      flex: 3;
      line-height: 45px;
      font-size: 14px;
      font-weight: 600;
      overflow: hidden;

      .home_intr_left_top {
        height: 56px;
        line-height: 56px;
        color: #f2cfd1;
        background-color: #c51722;
        display: flex;

        .home_intr_left_top_content {
          animation: topAn 30s linear infinite;
          transform: translate(100vw, 0);
          white-space: nowrap;
        }
      }

      @keyframes topAn {
        0% {
          transform: translate(0, 0);
        }

        100% {
          transform: translate(-94vw, 0);
        }
      }

      .home_intr_left_bottom {
        height: 20px;
        color: #836105;
        background-color: #e9c259;

        .home_intr_left_bottom_content {
          animation: bottomAn 10s linear infinite;
          transform: translate(200px, 0);
          white-space: nowrap;
        }
      }

      @keyframes bottomAn {
        0% {
          transform: translate(100px, 0);
        }

        100% {
          transform: translate(-300px, 0);
        }
      }
    }
  }
  .home_img_bg {
    background: url("../newImage/new_bg.jpg");
    background-size: cover;
    padding-bottom: 70px;
  }
  .home_news {
    display: flex;
    height: 380px;
    width: 1540px;
    overflow: hidden;
    padding: 0 30px;
    margin: 50px auto;
    margin-top: 50px;
    justify-content: space-between;
    .home_news_bg::after {
      content: " ";
      position: absolute;
      bottom: 0;
      left: 20px;
      width: 100%;
      height: 6%;
      background-color: #c51722;
      z-index: -1;
    }
    .home_news_bg::before {
      content: " ";
      position: absolute;
      bottom: -20px;
      right: -20px;
      width: 10%;
      height: 100%;
      background-color: #c51722;
      z-index: -1;
    }
    .home_news_bg {
      margin-top: 16px;
      width: 650px;
      position: relative;
      transform: translateX(-25px);
      img {
        transform: scale(1.4);
      }

      .home_news_bg_bottom {
        position: absolute;
        bottom: 14px;
        left: 0;
        z-index: 2;
        color: #fff;
        padding: 0 16px;
        font-size: 14px;
        font-weight: bold;
        width: calc(100% - 32px);
        height: 40px;
        line-height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #0000004d;

        .home_news_bg_bottom_title {
          cursor: pointer;
          width: 70%;
          white-space: nowrap;
          /* 防止文本换行 */
          overflow: hidden;
          /* 控制文本溢出时隐藏超出部分 */
          text-overflow: ellipsis;
          /* 当文本溢出时显示省略号 */
        }

        .home_news_bg_bottom_Index {
          float: left;
          color: #c51723;
          width: 20px;
          height: 18px;
          position: relative;
          line-height: 18px;
          border: 1px solid #c5172394;
          text-align: center;
          margin-right: 5px;
          cursor: pointer;
          background: #ffffff9a;
        }

        .home_news_bg_bottom_Index:hover {
          color: #fff;
          background: #c51723;
          border: 1px solid #c51723;
        }

        .active {
          color: #fff;
          background: #c51723;
          border: 1px solid #c51723;
        }
      }
    }

    .home_news_left {
      margin-right: 54px;
      width: 55%;
      padding-top: 20px;
      // width: calc(100% - 626px);
      .one {
        opacity: 0;
        animation: donghua 1.5s ease-in-out 0s infinite;
      }
      .two {
        opacity: 0;
        animation: donghua 1.5s ease-in-out 0.4s infinite;
      }
      .three {
        opacity: 0;
        animation: donghua 1.5s ease-in-out 0.8s infinite;
      }
      @keyframes donghua {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
      .bgred {
        width: 632px;
        height: 345px;
        top: 40px;
        background: #c51722;
        position: absolute;
        right: 30px;
        z-index: -10;
      }
      .wire {
        width: 139px;
        height: 3px;
        background: #c51722;
        margin-top: 15px;
      }
      .home_news_left_title_dom:hover {
        transform: translate(0, -5px);
        transition: all 0.3s ease-in-out 0s;
      }
      .home_news_left_title_center_dom:hover {
        transform: translate(0, -5px);
        transition: all 0.3s ease-in-out 0s;
      }
      .home_news_left_title_center_dom {
        margin: 30px 0 20px 0;
        height: 110px;
        position: relative;
        font-size: 20px;
        line-height: 30px;
        cursor: pointer;
        color: #5b5d5f;
      }
      .home_news_left_title {
        display: flex;
        width: 100%;
        // border-bottom: 3px solid #ddd;

        .home_news_left_title_name {
          margin-left: 10px;
          padding: 0 25px;
          height: 42px;
          line-height: 42px;
          text-align: center;
          border: 1px solid #ddd;
          background: #fff;
          color: #000;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
          cursor: pointer;
          font-size: 18px;
          font-weight: 800;
        }

        .home_news_left_title_name:first-child {
          margin-left: 0;
        }

        .active {
          background: #ff0000;
          color: #fff;
          transition: all 0.3s ease-in-out 0s;
        }
      }
      .home_news_left_list:hover {
        color: red;
      }
      .home_news_left_list {
        cursor: pointer;
        width: 100%;
        display: flex;
        height: 35px;
        align-items: center;
        .circle {
          background: #000;
          width: 5px;
          height: 5px;
          border-radius: 50%;
        }
        .home_news_left_list_title {
          width: 85%;
          font-size: 17px;
          // font-weight: 800;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: 15px;
          /* 当文本溢出时显示省略号 */
        }

        .home_news_left_list_day {
          font-size: 13px;
          color: #0000008c;
        }
      }
    }
  }

  .home_img {
    height: 955px;
    background: url("../newImage/group.jpg");
    background-size: cover;
    position: relative;
    // width: 1600px;
    // margin: 50px auto;
    // padding: 0 30px;
    .home_img_box {
      margin: 0 160px;
      padding-top: 150px;
    }
    .home_img_title {
      width: 970px;
      height: 120px;
      margin: 0 auto;
      background: url("../newImage/titleCenter.png");
      background-size: cover;
    }
    .home_img_center {
      margin: 0 auto;
      font-weight: 500;
      font-size: 27px;
      color: #5b0d0d;
      line-height: 48px;
      text-align: center;
      max-width: 1600px;
      margin-top: 55px;
      margin-bottom: 110px;
    }
    .home_img_details {
      display: flex;
      justify-content: center;
      .box {
        display: flex;
        align-items: center;
        .box_font {
          font-weight: 800;
          font-size: 70px;
          color: #9d1111;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
          display: flex;
          position: relative;
          padding-bottom: 10px;
          .span_font {
            font-size: 20px;
            color: #9d1111;
            text-shadow: none;
            display: flex;
            align-items: flex-end;
            padding-bottom: 20px;
            padding-left: 10px;
          }
          .title_font {
            position: absolute;
            bottom: -5px;
            opacity: 0.8;
            text-shadow: none;
            width: 150px;
            // color: #793a38;
            left: 15%;
            font-size: 15px;
          }
        }
      }
      .home_img_single {
        background: url("../../static/newImage/showDom.png");
        width: 3px;
        height: 88px;
        background-size: inherit;

        // border-radius: 50%;
        margin: 0 100px;
      }
    }
    .see_all {
      z-index: 10;
      font-size: 31px;
      color: #941c24;
      text-align: center;
      margin-top: 100px;
      cursor: pointer;
    }
    .homt_line {
      width: 100%;
      height: 393px;
      z-index: 20;
      position: absolute;
      background: url("../newImage/线条.png");
      bottom: -15%;
      background-size: cover;
    }
  }

  .home_all {
    max-width: 1540px;
    margin: 0 auto;
    margin-bottom: 50px;
    position: relative;
    padding: 0 30px;

    .home_all_item {
      width: 1600px;

      .home_all_item_index {
        cursor: pointer;
        position: relative;
      }
    }

    .home_all_module {
      display: flex;
      color: #cc0000;
      font-size: 18px;
      font-weight: 800;
      overflow: hidden;

      .swiper-container1,
      .swiper-container2,
      .swiper-container3,
      .swiper-container4,
      .swiper-container5,
      .swiper-container99,
      .swiper-container92,
      .swiper-container6 {
        width: 32%;
        margin: 0;
        margin-right: 2%;
      }

      .swiper-container3,
      .swiper-container6 {
        margin-right: 0;
      }

      .home_all_module_name {
        margin-bottom: 20px;
        width: 100%;
        margin-right: 2%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .home_all_module_name_more {
          font-size: 14px;
          color: #a68862;
          font-weight: 500;
          cursor: pointer;
        }
      }

      .home_all_module_img {
        cursor: pointer;
      }
      .blackMack {
        width: 493px;
        height: 235px;
        position: absolute;
        top: 0;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.43) 100%
        );
      }
      ::v-deep .swiper-pagination-bullet-active {
        background: red !important;
      }
      ::v-deep .swiper-pagination-bullet {
        // color: #fff;
        background: #fff;
      }
      .home_all_content {
        margin-top: 10px;
        // border: 1px solid #ddd;
        overflow: hidden;
        padding: 10px 15px;
        padding-left: 0;
        width: 100%;

        .home_all_content_item {
          cursor: pointer;
          color: #333;
          white-space: nowrap;
          overflow: hidden;
          font-weight: 500;
          text-overflow: ellipsis;
          font-size: 14px;
          line-height: 30px;
          position: relative;
          padding-left: 20px;
          text-align: left;
        }
        .home_all_content_item:hover {
          color: #c51722;
        }
        .home_all_content_item::before {
          content: "";
          background-color: #000;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 12px;
        }
      }

      .home_all_module_black {
        width: 100%;
        margin-right: 2%;
        height: 400px;
        overflow: hidden;

        .home_all_module_black_intro {
          width: 340px;
          height: 400px;
          background: rgba(0, 0, 0, 0.7);
          color: #fff;
          text-align: left;
          font-size: 14px;
          font-weight: 500;
          padding: 0 30px;
          margin-top: 280px;
          line-height: 20px;
          cursor: pointer;

          .home_all_module_black_intro_title {
            font-size: 32px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 50px;
            line-height: 60px;
            padding-top: 20px;
            text-align: center;

            .home_all_module_black_intro_title_red {
              background-color: red;
              height: 2px;
              width: 30px;
              margin: 0 auto;
              transition: all 1s ease-in-out 0s;
            }
          }

          .home_all_module_black_intro_content {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            line-height: 24px;
          }

          .home_all_module_black_intro_more {
            margin-top: 40px;
            text-align: center;
            color: #ffffffbb;
          }
        }

        @keyframes changeMargin {
          0% {
            margin-top: 230px;
          }

          100% {
            margin-top: 0;
          }
        }
      }

      .home_all_module_img:last-child,
      .home_all_module_name:last-child,
      .home_all_module_black:last-child {
        margin-right: 0;
      }
    }

    .home_all_bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .home_animationS {
    .animationS {
      margin-top: 10px;
      width: 1600px;
      font-family: "MaShanZheng-Regular", sans-serif;
      color: #fff;
      font-size: 35px;
      line-height: 60px;
      text-align: center;
      position: relative;
      margin: 0 auto;
    }

    .animationS .first-show,
    .animationS .second-show {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1, 1);
      font-family: 楷体;
    }

    .animationS .third-show {
      text-align: center;
      // width: calc(100% - 40px);
      // height: 370px;
      font-size: 18px;
      line-height: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1, 1);
      font-family: 楷体;
    }

    .animationS .second-show {
      transform: translate(-50%, -50%) scale(0, 0);
    }

    .animationS .first-show-title,
    .animationS .second-show-title {
      font-size: 35px;
    }

    .animationS .first-show-line,
    .animationS .second-show-line {
      height: 3px;
      background-color: #fff;
      width: 50px;
      margin: 50px auto;
    }

    .animationS .first-show {
      animation: Fshow 11s linear forwards;
      animation-iteration-count: infinite;
    }

    @keyframes Fshow {
      0% {
        transform: translate(-50%, -50%) scale(0, 0);
      }

      9% {
        transform: translate(-50%, -50%) scale(0, 0);
      }

      10% {
        transform: translate(-50%, -50%) scale(1, 1);
      }

      20% {
        transform: translate(-50%, -50%) scale(1, 1);
      }

      42% {
        transform: translate(-50%, -50%) scale(0, 0);
      }

      100% {
        transform: translate(-50%, -50%) scale(0, 0);
      }
    }

    .animationS .second-show {
      animation: Sshow 11s linear forwards;
      animation-iteration-count: infinite;
    }

    @keyframes Sshow {
      0% {
        transform: translate(-50%, -50%) scale(0, 0);
      }

      43% {
        transform: translate(-50%, -50%) scale(0, 0);
      }

      64% {
        transform: translate(-50%, -50%) scale(0.8);
      }

      80% {
        transform: translate(-50%, -50%) scale(0.8);
      }

      100% {
        transform: translate(-50%, -50%) scale(0, 0);
      }
    }
  }

  .home_ten {
    width: 100%;
    background: url("../../static/newImage/chanye2.png") no-repeat;
    background-size: cover;

    .home_box {
      margin: 0 auto 80px auto;

      .box {
        width: 100%;
      }

      .boxImg {
        width: 100%;
        text-align: center;
        overflow: hidden;
      }

      .centre {
        width: 1010px;
        height: 664px;
        margin: 0 auto;
        margin-bottom: 20px;
        display: flex;
        position: relative;
        flex-wrap: wrap;
        align-content: center;
      }

      .all-btn {
        display: flex;
        z-index: 99;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 40px;
      }

      .l-btn {
        height: 40px;
        box-shadow: 0px 2px 15px 0px rgba(197, 23, 34, 0.12);
        border-radius: 21px;
        font-size: 20px;
        font-weight: 500;
        color: #8c8f92;
        line-height: 40px;
        letter-spacing: 1px;
        cursor: pointer;
        padding: 0 21px;
      }

      .l-btn.active {
        background: #c51722;
        box-shadow: 0px 2px 15px 0px rgba(255, 0, 0, 0.23);
        color: #ffffff;
      }

      .centreSty {
        background-color: #e23127 !important;
        color: #fff !important;
      }

      .boxText {
        width: 1150px;
        height: 300px;
        background: #f5f5f7;
        margin: 0 auto;
      }

      .boxcentre {
        display: flex;
        padding: 25px;
        justify-content: flex-start;
      }

      .boxcentreRImg {
        /* background-color: red; */
        width: 300px;
        height: 400px;
      }

      .boxcentreR {
        overflow: hidden;
        height: 244px;
        width: 500px;
      }

      .boxcentreL {
        width: 800px;
        font-size: 16px;
        font-weight: 400;
        color: #5b5d5f;
        line-height: 23px;
        letter-spacing: 1px;
        margin-left: 30px;
        text-align: left;
        position: relative;
      }
    }

    .home_btn {
      margin: 80px auto;
      width: 1600px;
      position: relative;

      .home_btn_left,
      .home_btn_right {
        bottom: 350px;
        border: none;
        outline: 0;
        padding: 0;
        margin: 0;
        height: 70px;
        width: 70px;
        font-size: 30px;
        font-weight: bold;
        line-height: 70px;
        cursor: pointer;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        border-radius: 50%;
        background-color: #040404a1;
        color: #fff;
        position: absolute;
        z-index: 10;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        text-align: center;
      }

      .home_btn_left {
        left: 30px;
      }

      .home_btn_right {
        left: 1100px;
      }
    }
  }
  .culture_top_img {
    background: url("../newImage/123.png");
    background-size: cover;
    position: absolute;
    background-position: top;
    // background-position: center;

    z-index: 30;
    width: 100%;
    height: 146px;
    top: -140px;
  }
  .swiper-container99 {
    height: 527px;
    overflow: hidden;
    margin: 0 auto;
    margin-top: 150px;
  }

  .swiper-container99 .swiper-wrapper .swiper-slide {
    width: 1020px;
    background-size: cover !important;
    overflow: hidden;
    cursor: pointer;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swiper-container99 .swiper-wrapper .swiper-slide img {
    width: 100%;
    height: 380px;
    border-radius: 5px;
  }
  ::v-deep .swiper-container99 .swiper-wrapper .swiper-slide-prev {
    background: red;
    position: relative;
    .frame {
      position: absolute;
      top: 0;
      width: 1021px;
      height: 403px;
      background: url("../newImage/frame.png");
      background-size: contain;
      background-repeat: round;
    }
    .open1 {
      background: url("../newImage/open2.png");
      background-size: cover;
      width: 100px;
      height: 100px;
      transform: translateX(205px);
      z-index: 10;
    }
  }
  .swiper-container99 .swiper-wrapper .swiper-slide-active {
    width: 1054px !important;
    position: relative;
    .frame {
      position: absolute;
      top: 0;
      width: 1060px;
      height: 530px;
      background: url("../newImage/frame.png");
      background-size: contain;
      background-repeat: round;
    }
    .open1 {
      background: url("../newImage/open1.png");
      background-size: cover;
      width: 155px;
      height: 155px;
      z-index: 10;
    }
  }
  .swiper-container99 .swiper-wrapper .swiper-slide-next {
    .open1 {
      background: url("../newImage/open2.png");
      background-size: cover;
      width: 100px;
      height: 100px;
      transform: translateX(-205px);
      z-index: 10;
    }
  }
  .swiper-container99 .swiper-wrapper .swiper-slide-prev,
  .swiper-container99 .swiper-wrapper .swiper-slide-next {
    // border-radius: 25px;
    height: 400px !important;
    position: relative;
    .frame {
      position: absolute;
      top: 0;
      width: 1021px;
      height: 403px;
      background: url("../newImage/frame.png");
      background-size: contain;
      background-repeat: round;
    }

    // margin-top: 34px;
    // border-radius: 25px;
    // height: 340px !important;
    // width: 596px !important;
    margin-top: 65px;
    .mask {
      // width: 1020px;
      // height: 400px;
      height: 400px !important;
      // width: 596px !important;
      bottom: 0;
      position: absolute;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.83) 100%
      );
    }
  }
  .swiper-container99 .swiper-wrapper .swiper-slide-prev img,
  .swiper-container99 .swiper-wrapper .swiper-slide-next img {
    width: 100%;
    height: 100%;
  }
  .culture_img_bg {
    background: url("../newImage/456.jpg");
    background-size: cover;
    position: relative;
    // background-position: center;
    background-position: top;
    // min-width: 1600px;
    // height: 763px;
    // height: 655px;
    .examples {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      overflow: auto;
      font-size: 14px;
      // max-width: 1902px;
      margin: 0 auto;
      .bannerBox {
        overflow: hidden;
        // max-width: 1600px;
        // height: 380px;
        // margin: 0 auto;
        // border-radius: 25px;
      }

      .mask {
        width: 1020px;
        height: 400px;
        bottom: 0;
        position: absolute;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.83) 100%
        );
      }

      .tips {
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 46px;
        background-color: #b5654d;
        text-align: center;

        img {
          width: 12px;
          height: 14px;
          position: absolute;
          top: 7.5px;
          left: 12vw;
          color: #fff;
        }

        p {
          width: 240px;
          text-align: left;
          color: #fff;
          font-size: 12px;
          position: absolute;
          left: 18vw;
          top: 7px;
          line-height: 16px;
        }
      }
    }
  }
  .society_contribution {
    height: 1048px;
    background: url("../newImage/bg_society.jpg");
    background-size: cover;
    position: relative;
    min-width: 1600px;
    .social_contribution_title {
      .social_contribution_title_img {
        background: url("../newImage/social_contribution_title.png");
        background-size: cover;
        height: 105px;
        position: absolute;
        left: 50%;
        width: 426px;

        transform: translate(-50%, 300%);
      }
      min-width: 1600px;
      height: 500px;
    }
    .bannerBox {
      height: 450px;
      // position: absolute;
      // overflow: hidden;

      .swiper-container92 {
        max-width: 1600px;
        height: 380px;
        margin: 0 auto;
        overflow: hidden;
        border-radius: 25px;
      }
      .swiper-container92 .swiper-wrapper .swiper-slide-active {
        height: 310px !important;
      }
      .swiper-container92 .swiper-wrapper .swiper-slide {
        border-radius: 15px;
        // background-size: cover !important;
        background-position: center center !important; /* 水平居中和垂直居中 */
        background-size: cover !important; /* 背景图片覆盖整个容器 */
        background-repeat: no-repeat !important; /* 不重复背景图片 */
        height: 310px !important;
        overflow: hidden;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .swiper-container92 .swiper-wrapper .swiper-slide img {
        width: 100%;
        height: 380px;
        border-radius: 5px;
      }

      .swiper-container92 .swiper-wrapper .swiper-slide-prev,
      .swiper-container92 .swiper-wrapper .swiper-slide-next {
        border-radius: 25px;
        height: 310px !important;
      }

      .swiper-container92 .swiper-wrapper .swiper-slide-prev img,
      .swiper-container92 .swiper-wrapper .swiper-slide-next img {
        width: 100%;
        height: 100%;
      }
      .swiper-pagination92 {
        // margin-top: 75px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      ::v-deep .swiper-pagination-bullet {
        margin: 0 8px;
        width: 66px;
        height: 12px;
        background: #000000;
        opacity: 0.08;
        border-radius: 6px;
      }

      ::v-deep .swiper-pagination-bullet-active {
        opacity: 1;
        width: 120px;

        background: #c51722;
      }
    }
  }
  .culture_propaganda {
    background: url("../newImage/culture_propaganda.png");
    background-size: cover;
    width: 426px;
    position: absolute;
    height: 105px;
    left: 50%;
    transform: translate(-50%, 100%);
  }
}
.enterprise_history {
  height: 1160px;
  background: url("../newImage/boss_bg.jpg");
  background-size: cover;
  position: relative;
  .enterprise_history_title {
    .enterprise_history_title_img {
      background: url("../newImage/Enterprise_history.png");
      background-size: cover;
      height: 105px;
      position: absolute;
      left: 50%;
      width: 426px;
      transform: translate(-52%, 150%);
    }
    .enterprise_history_blank {
      // background: url();
      height: 336px;
      min-width: 1600px;
      background: rgba(0, 0, 0, 0);
    }
    .enterprise_history_wave_fa_momentarily {
      .c_dom {
        width: 1600px;
        height: 650px;
        left: 50%;
        // background: #333;
        transform: translateX(-50%);
        position: absolute;
      }
      height: 650px;
      width: 1600px;
      margin: 0 auto;
      background: url("../newImage/momentarily.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position-x: center;
      position: relative;
      .enterprise_history_wave_dom_one {
        width: 290px;
        height: 240px;
        position: absolute;
        bottom: 135px;
        left: 2.5%;
        // background: skyblue;
        cursor: pointer;
      }
      .enterprise_history_wave_dom_two {
        width: 290px;
        height: 240px;
        position: absolute;
        bottom: 270px;
        left: 455px;
        // background: red;
        cursor: pointer;
      }
      .enterprise_history_wave_dom_three {
        width: 290px;
        height: 240px;
        position: absolute;
        bottom: 232px;
        right: 455px;
        // background: pink;
        cursor: pointer;
      }
      .enterprise_history_wave_dom_four {
        width: 290px;
        height: 240px;
        position: absolute;
        bottom: 180px;
        right: 45px;
        // background: yellow;
        cursor: pointer;
      }
    }
    .enterprise_history_wave_fa {
      height: 336px;
      min-width: 1600px;
      background: url("../newImage/wave.png");
      background-size: cover;
      // background-position-x: 150px;
    }
    .enterprise_history_wave {
      height: 336px;
      margin: 0 auto;
      width: 1600px;
      background: url("../newImage/wave.png");
      background-size: cover;
      display: flex;
      justify-content: space-evenly;
      .enterprise_history_box0 {
        position: relative;
        // color: red;
        .enterprise_history_alone {
          position: absolute;
          bottom: 3%;
          transform: translate(-50%, 280px);
          width: 360px;

          text-align: center;
          .enterprise_history_circle {
            width: 22px;
            height: 22px;
            background: #85704b;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 50%;
            border: 8px solid #fff;
            // transform: translateX(-14px);
          }
          .enterprise_history_year {
            width: 215px;
            height: 40px;
            display: inline-block;
            font-weight: 400;
            // transform: translateX(-50%);
            font-size: 40px;
            color: #af0c0c;
            line-height: 48px;
          }
          .enterprise_history_img {
            width: 280px;
            height: 158px;
            background: url("../newImage/456.jpg");
            background-size: cover;
            background-position-x: 50%;
            position: absolute;
            right: 50%;
            transform: translateX(50%);
            border: 2px solid #e0aa72;
          }
          .enterprise_history_alone_title {
            margin-top: 20px;
            font-weight: bold;
            font-size: 22px;
            color: #945c31;
            line-height: 34px;
            text-align: center;
            font-style: normal;
            text-transform: uppercase;
          }
        }
      }
      .enterprise_history_box1 {
        position: relative;
        // color: red;
        .enterprise_history_alone {
          position: absolute;
          bottom: 3%;
          transform: translate(-50%, 130px);
          width: 360px;

          text-align: center;
          .enterprise_history_circle {
            width: 22px;
            height: 22px;
            background: #85704b;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 50%;
            border: 8px solid #fff;
            // transform: translateX(-14px);
          }
          .enterprise_history_year {
            width: 215px;
            height: 40px;
            display: inline-block;
            font-weight: 400;
            // transform: translateX(-50%);
            font-size: 40px;
            color: #af0c0c;
            line-height: 48px;
          }
          .enterprise_history_img {
            width: 280px;
            height: 158px;
            background: url("../newImage/456.jpg");
            background-size: cover;
            background-position-x: 50%;
            position: absolute;
            right: 50%;
            transform: translateX(50%);
            border: 2px solid #e0aa72;
          }
          .enterprise_history_alone_title {
            margin-top: 20px;
            font-weight: bold;
            font-size: 22px;
            color: #945c31;
            line-height: 34px;
            text-align: center;
            font-style: normal;
            text-transform: uppercase;
          }
        }
      }
      .enterprise_history_box2 {
        position: relative;
        // color: red;
        .enterprise_history_alone {
          position: absolute;
          bottom: 3%;
          transform: translate(-50%, 115px);
          width: 360px;

          text-align: center;
          .enterprise_history_circle {
            width: 22px;
            height: 22px;
            background: #85704b;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 50%;
            border: 8px solid #fff;
            // transform: translateX(-14px);
          }
          .enterprise_history_year {
            width: 215px;
            height: 40px;
            display: inline-block;
            font-weight: 400;
            // transform: translateX(-50%);
            font-size: 40px;
            color: #af0c0c;
            line-height: 48px;
          }
          .enterprise_history_img {
            width: 280px;
            height: 158px;
            background: url("../newImage/456.jpg");
            background-size: cover;
            background-position-x: 50%;
            position: absolute;
            right: 50%;
            transform: translateX(50%);
            border: 2px solid #e0aa72;
          }
          .enterprise_history_alone_title {
            margin-top: 20px;
            font-weight: bold;
            font-size: 22px;
            color: #945c31;
            line-height: 34px;
            text-align: center;
            font-style: normal;
            text-transform: uppercase;
          }
        }
      }
      .enterprise_history_box3 {
        position: relative;
        // color: red;
        .enterprise_history_alone {
          position: absolute;
          bottom: 3%;
          transform: translate(-50%, 210px);
          width: 360px;

          text-align: center;
          .enterprise_history_circle {
            width: 22px;
            height: 22px;
            background: #85704b;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 50%;
            border: 8px solid #fff;
            // transform: translateX(-14px);
          }
          .enterprise_history_year {
            width: 215px;
            height: 40px;
            display: inline-block;
            font-weight: 400;
            // transform: translateX(-50%);
            font-size: 40px;
            color: #af0c0c;
            line-height: 48px;
          }
          .enterprise_history_img {
            width: 280px;
            height: 158px;
            background: url("../newImage/456.jpg");
            background-size: cover;
            background-position-x: 50%;
            position: absolute;
            right: 50%;
            transform: translateX(50%);
            border: 2px solid #e0aa72;
          }
          .enterprise_history_alone_title {
            margin-top: 20px;
            font-weight: bold;
            font-size: 22px;
            color: #945c31;
            line-height: 34px;
            text-align: center;
            font-style: normal;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .enterprise_history_all {
    background: url("../newImage/矩形.png");
    background-size: cover;
    position: absolute;
    min-width: 1500px;
    height: 50px;
    left: 50%;
    bottom: 10%;
    transform: translateX(-50%);
    .enterprise_history_all_see {
      background: url("../newImage/了解更多 _.png");
      background-size: cover;
      width: 137px;
      height: 27px;
      position: absolute;
      bottom: 25%;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
  }
}
::v-deep .swiper-container7 {
  .swiper-slide-next {
    .active {
      animation: changeMargin 1s linear forwards;

      .home_all_module_black_intro_title_red {
        width: 60px !important;
      }

      .home_all_module_black_intro_title {
        padding-top: 60px;
      }
    }
  }
}

.swiper-container {
  --swiper-theme-color: #e11515;
}

::v-deep .el-carousel__arrow {
  transform: scale(1.7);
  background-color: #1f2d3d80;
}

::v-deep .el-carousel__arrow--right {
  right: 50px;
  display: none;
}

::v-deep .el-carousel__arrow--left {
  left: 50px;
  display: none;
}

::-webkit-scrollbar {
  width: 0.5em;
  /* 设置滚动条的宽度 */
}
::v-deep .is-active .el-carousel__button {
  width: 62px;
  // height: 6px;
  background: #ffeac1;
}
.home_eventss {
  background: url("../newImage/bg.jpg");
  background-size: cover;
  display: flex;
  justify-content: center;
  padding: 70px 0 100px 0;
  // padding: 70px 20px 100px 20px;
  .eventss_bg {
    width: 1600px;
    // height: 800px;
    .eventss_title {
      background: url("../newImage/编组 27@2x.png");
      width: 400px;
      height: 175px;
      background-size: cover;
    }
    .headTv {
      width: 1540px;
      height: 848px;
      position: relative;
      margin: 0 auto;
      padding: 0 30px;
      // background: url("../../../static/v1.0/frame.png");
      .headBox {
        padding-top: 40px;
        overflow: hidden;
        width: 1540px;
        height: 800px;
        margin: 0 auto;
        border-radius: 15px;
        display: flex;
        .head_img {
          border-radius: 15px;
          ::v-deep .el-carousel__container {
            height: 800px;
          }
          min-width: 1540px;
          height: 800px;
          margin: 0 auto;
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.13) 0%,
            rgba(0, 0, 0, 0.83) 100%
          );

          .head_bg {
            .mask {
              width: 1540px;
              height: 400px;
              bottom: 0;
              position: absolute;
              background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.83) 100%
              );
            }
          }

          .head_item {
            position: absolute;
            bottom: 30px;
            left: 64px;

            .head_font {
              width: 1000px;
              // overflow: hidden;
              // text-overflow: ellipsis;
              // word-break: break-all;
              // display: -webkit-box;
              // -webkit-box-orient: vertical;
              // -webkit-line-clamp: 2;
              // height: 104px;
              font-weight: bold;
              font-size: 32px;
              color: #ffffff;
              line-height: 52px;
              letter-spacing: 1px;
              text-align: justify;
              font-style: normal;
            }

            .head_font1 {
              font-weight: 500;
              font-size: 24px;
              color: #ffffff;
              line-height: 36px;
              letter-spacing: 1px;
              text-align: left;
              font-style: normal;
              // opacity: 0.9;
            }
          }
        }
      }
      .cornerMarkBox {
        z-index: 8;
        position: absolute;
        width: 180px;
        display: flex;
        bottom: 64px;
        right: 66px;
        cursor: pointer;
        justify-content: flex-end;

        .cornerMark {
          width: 36px;
          height: 36px;
          color: #fff;
          text-align: center;
          font-weight: 500;
          font-size: 18px;
          line-height: 36px;
          margin-left: 10px;

          background: rgba(255, 255, 255, 0.2);
          border: 1px solid rgba(255, 255, 255, 0.45);
        }

        .ckcss {
          background: #c51723;
          // background: rgba(65, 47, 27, 0.8);
          font-weight: bold;
          font-size: 18px;
        }
      }
    }
  }
}
::v-deep .el-dialog {
  background: transparent;
  box-shadow: none;
  width: 100% !important;
}
::v-deep .el-dialog > .el-dialog__header {
  display: none;
}
::v-deep .el-dialog > .el-dialog__body {
  background: transparent;
  box-shadow: none;
  text-align: center;
  padding-top: 50%;
}
</style>
